import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { SwiperComponent } from '../components/SwiperComponent'

export const MainPage = () => {



	return (
		<>
			<Navbar />
			<div className="announcement open">
				<div className="wrapper1">
					<marquee behavior="alternate" direction="left"> <span className="announcement__title">Welcome to Brandon Consulting Associates,Inc!</span>
						<p id="data" style={{ fontSize: '21px' }}>Brandon Consulting Associates - Celebrating {new Date().getFullYear() - new Date(1996)} Years In Business!</p>
					</marquee>
				</div>
			</div>
			<section id="hero" className="d-flex flex-column justify-content-center align-items-center">
				<div className="container" data-aos="fade-in">
					<h1>Welcome to Brandon Consulting Associates,Inc</h1>
					<h2>Targeted IT Staffing Solutions</h2>
				</div>
			</section>
			<main id="main">

				<section id="why-us" className="why-us">
					<div className="container">
						<div className="row">
							<div className="col-xl-4 col-lg-5" data-aos="fade-up">
								<div className="content">
									<h3>Why Choose BCA?</h3>
									<p> We provide all your IT Services </p>
									<div className="text-center"> <Link to="/about" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link> </div>
								</div>
							</div>
							<div className="col-xl-8 col-lg-7 d-flex">
								<div className="icon-boxes d-flex flex-column justify-content-center">
									<div className="row">
										<div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
											<div className="icon-box mt-4 mt-xl-0"> <i className="bx bx-receipt"></i>
												<p>Brandon Consulting Associates Inc is a full-service specialized staffing firm providing temporary and permanent staffing solutions for clients worldwide.</p>
											</div>
										</div>
										<div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
											<div className="icon-box mt-4 mt-xl-0"> <i className="bx bx-cube-alt"></i>
												<p>We make sure we know what your specific needs are, and work with you to find exactly the right people who fully understand your business and have the technical expertise to see that your project is completed on time and within budget. Where some companies only want to fill seats, we want to add value to your company and to build a relationship with you. </p>
											</div>
										</div>
										<div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
											<div className="icon-box mt-4 mt-xl-0"> <i className="bx bx-images"></i>
												<p> We also offer project consulting including the design, development and integration of existing or customized applications and databases.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="services" className="services section-bg">
					<div className="container">
						<div className="section-title" data-aos="fade-up">
							<h2>Services</h2> </div>
						<div className="row">
							<div className="col-lg-4 col-md-6" data-aos="fade-up">
								<div className="icon-box">
									<div className="icon"><i className="bi bi-briefcase"></i></div>
									<h1 className="title"><Link to="/services#card1">IT Staffing and Consulting</Link></h1> </div>
							</div>
							<div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
								<div className="icon-box">
									<div className="icon"><i className="bi bi-card-checklist"></i></div>
									<h4 className="title">

										<Link to="/services#card2">Project Consulting</Link></h4> </div>
							</div>
							<div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
								<div className="icon-box">
									<div className="icon"><i className="bi bi-bar-chart"></i></div>
									<h4 className="title"><Link to="/services#card3">Vendor Management</Link></h4> 
									</div>
							</div>
							<div className="text-center" data-aos="fade-up" data-aos-delay="250"> <Link to="/services" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link> </div>
						</div>
					</div>
				</section>

				<section id="values" className="values">
					<div className="container">
						<div className="row">
							<h2 style={{
								textAlign: 'center',
								color: '#092443', font: 'bolder', fontFamily: 'Poppins,sans-serif'
							}} data-aos="fade-up">Our Commitment</h2>
							<div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
								<div className="card cards1">
									<div className="card-body_1">
										<h5 className="card-title">Quality</h5>
										<p className="hide">BCA is committed to providing high-quality, value-added services to its clients</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
								<div className="card card2">
									<div className="card-body_1">
										<h5 className="card-title">Adaptive</h5>
										<p className="hide">By virtue of being flexible, BCA is adaptive and sensitive to its clients needs</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="200">
								<div className="card card3">
									<div className="card-body_1">
										<h5 className="card-title">Reliable</h5>
										<p className="hide">BCA is committed to consistently deliver solutions and resources precisely within, if not exceeding the parameters set by the client</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="300">
								<div className="card card4">
									<div className="card-body_1">
										<h5 className="card-title">Training and Development</h5>
										<p className="hide">BCA is committed to ongoing training and development of its employees, which directly impacts its clients by being able to provide an up-to-date knowledge base and skills </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="testimonials" className="testimonials">

					<div className="container position-relative" data-aos="fade-up">
						<div className="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
							<SwiperComponent />
							<div className="swiper-pagination"></div>
						</div>
					</div>

				</section>

				<section id="contact" className="contact">
					<div className="container">
						<div className="section-title">
							<h2 data-aos="fade-up">Contact Us</h2> </div>
						<div className="row justify-content-center">
							<div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
								<div className="info-box"> <i className="bx bx-map"></i>
									<h3>Our Address</h3>
									<p>30 Silverline Drive,Suite #3,2nd Floor, North Brunswick, NJ 08902
										<br /><a href="https://www.google.com/maps/search/2+King+Arthur+Ct,+Suite+A,+North+Brunswick,+NJ+08902/@40.460323,-74.4368621,17z/data=!3m1!4b1" target="_blank">Click here for map</a></p>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="info-box"> <i className="bx bx-envelope"></i>
									<h3>Email Us</h3>
									<p>info@brandonconsulting.com</p>
									<br />
									<br /> </div>
							</div>
							<div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
								<div className="info-box"> <i className="bx bx-phone-call"></i>
									<h3>Call Us</h3>
									<p> (732) 650-9121</p>
									<br />
									<br /> </div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />

		</>

	)
}