import React from "react";

import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Link } from "react-router-dom";

export const About = () =>{
    return(
        <>
		<Navbar />
        	<main id="main">
	    
		<section id="breadcrumbs" className="breadcrumbs" style={{ paddingLeft: "72px"}}>
			<div className="container">
				<ol>
					<li><Link to="/">Home</Link></li>
					<li>About</li>
				</ol>
				<h2>About Brandon Consulting</h2> </div>
		</section>

		<section id="about" className="about section-bg" style={{paddingLeft:"30px"}}>
			<div className="container">
				<div style={{fontSize: "larger"}}>
					<ul data-aos="fade-up">
						<li style={{backgroundColor:"#FFF9F7"}}>Established in 1996, Brandon Consulting has evolved into a highly reputable Information Technology solutions and Resource Management company.</li>
						<li style={{backgroundColor:"#FFF9F7"}}>Over the past 25+ years, we have gained a reputation for quality, reliability and value among its clients across the US.</li>
						<li style={{backgroundColor:"#FFF9F7"}}>With an eye on quality, we have attracted, recruited and retained exceptional talent from both local and overseas markets. Candidates undergo stringent screening processes to ensure the highest standards in quality, both technical and personal.</li>
					</ul>
					<div>
						<div className="icon-box" data-aos="fade-up">
							<div className="icon"><i className="bx bx-fingerprint"></i></div>
							<h4 className="title">Our Mission</h4>
							<p className="description">To help our clients leverage Information Technologies and manage their software projects better by providing qualified and experienced IT resources. </p>
						</div>
						<div className="icon-box" data-aos="fade-up" data-aos-delay="100">
							<div className="icon"><i className="bx bx-gift"></i></div>
							<h4 className="title">Our Values</h4>
							<p className="description">People come first - We strive to be the best in our field - We share knowledge freely - Honesty and Integrity are paramount.</p>
						</div>
						<div className="icon-box" data-aos="fade-up" data-aos-delay="200">
							<div className="icon"><i className="bx bx-atom"></i></div>
							<h4 className="title">Recruiters</h4>
							<p className="description">People come first - We strive to be the best in our field - We share knowledge freely - Honesty and Integrity are paramount</p>
						</div>
						<div className="icon-box" data-aos="fade-up" data-aos-delay="300">
							<div className="icon"><i className="bx bx-briefcase"></i></div>
							<h4 className="title">Vendors</h4>
							<p className="description">From systems analysts to database administrators, our recruits represent the world's experts in current, high-demand and/or obscure technology platforms. Brandon Consulting is committed to providing high quality, value–added services to fit our clients’ needs and surpass their expectations.
								<br/><Link to="/services"><strong>Services</strong></Link></p>
						</div>
						<div className="icon-box" data-aos="fade-up" data-aos-delay="400">
							<div className="icon"><i className="bx bx-home-alt"></i></div>
							<h4 className="title">Employees</h4>
							<p className="description">We are committed to our employees, offering ongoing training and development, which directly impacts our clients by providing staff that keeps up with ever-changing technologies.
								<br/><Link to="/careers"><strong>Careers</strong></Link> </p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
        <Footer />
        </>
    )
}