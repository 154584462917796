import React from "react";
import { Link } from "react-router-dom";

import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";

export const Careers = () => {
    const execScript = () => {
		var script = document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://jobsapi.ceipal.com/APISource/widget.js";
		script.setAttribute('data-ceipal-api-key', "MTFyaGZDcGJvcGkvSW1GbWxzRjlVUT09");
		document.body.appendChild(script);
	}
    return (
        <>
        <Navbar />
            <section id="breadcrumbs" className="breadcrumbs" style={{ padding: "72px" }}>
                <div className="container">
                    <ol>
                        <li><Link to="/">Home</Link></li>
                        <li>Careers</li>
                    </ol>
                    <h2>Careers</h2> </div>
            </section>
            <section id="about" className="about section-bg" data-aos="fade-up" style={{ paddingLeft: "30px", fontSize: "larger" }}>
                <div className="container">
                    <ul>
                        <li><strong>We know you are much more than just a resume: </strong>Our recruiters want to know your personal strengths, leadership ability, and communications skills as well as your technical ability. By understanding you, we will be able to match you to requirements that will enable you to advance in your career. </li>
                        <li><strong>We value Professionalism and Respect:</strong> We are committed to our employees, offering ongoing training and development opportunities, and ensuring that </li>
                        <li><Link to="/capabilities"><strong>Capability</strong></Link></li>
                        <li><a href="#/careers" onclick={execScript()}><strong>Requirements</strong></a>
                            <div id="example-widget-container"></div>
                        </li>
                        
                    </ul>
                </div>
            </section>
            <section id="contact" className="contact">
                <div className="container">
                    <div className="section-title">
                        <h2 data-aos="fade-up">Contact Us</h2> </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
                            <div className="info-box"> <i className="bx bx-map"></i>
                                <h3>Our Address</h3>
                                <p> 30 Silverline Drive,Suite #3,2nd Floor, North Brunswick, NJ 08902
                                    <br /><a href="https://www.google.com/maps/search/2+King+Arthur+Ct,+Suite+A,+North+Brunswick,+NJ+08902/@40.460323,-74.4368621,17z/data=!3m1!4b1" target="_blank">Click here for map</a></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="info-box"> <i className="bx bx-envelope"></i>
                                <h3>Email Us</h3>
                                <p>info@brandonconsulting.com</p>
                                <br />
                                <br /> </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="info-box"> <i className="bx bx-phone-call"></i>
                                <h3>Call Us</h3>
                                <p> (732) 650-9121</p>
                                <br />
                                <br /> </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}