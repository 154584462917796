import React from 'react';
import { Link } from 'react-router-dom';


export const Footer = () => {
    return (
        <div>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* First Column */}
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3 style={{ color: '#092443' }}>Brandon Consulting Associates</h3>
                                <p>30 Silverline Drive, Suite #3, 2nd Floor <br />
                                    North Brunswick NJ 08902  <br />
                                    United States <br /> <br />
                                    <strong>Phone: </strong>
                                    (732) 650-9121 <br />
                                    <strong>Email:</strong>
                                    info@brandonconsulting.com"
                                    <br />
                                </p>
                            </div>
                            {/* Second Column */}
                            <div className='col-lg-2 col-md-6 footer-links'>
                                <h4 style={{ color: '#092443' }}>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/'>Home</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/'>Home</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/about'>About us</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/about'>About us</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a  style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/services'>Services</a> */}
                                            <Link  style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/services'>Services</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/capabilities'>Capability</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/capabilities'>Capability</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/careers'>Careers</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/careers'>Careers</Link>
                                        </i>
                                    </li>
                                </ul>
                            </div>
                            {/* Third Column Footer */}
                            <div className='col-lg-3 col-md-6 footer-links'>
                                <h4 style={{ color: '#092443' }}>Our Services</h4>
                                <ul>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/services'>IT Staffing</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/services'>IT Staffing</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/services'>Project Consulting</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/services'>Project Consulting</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/services'>Vendor Management</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/services'>Vendor Management</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/services'>Staff Augmentation</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/services'>Staff Augmentation</Link>
                                        </i>
                                    </li>
                                    <li>
                                        <i className='bx bx-chevron-right'>
                                            {/* <a style={{ fontFamily: "Open Sans",fontSize:"14px"}} href='/services'>Quality Management</a> */}
                                            <Link style={{ fontFamily: "Open Sans",fontSize:"14px"}} to='/services'>Quality Management</Link>
                                        </i>
                                    </li>
                                </ul>
                            </div>
                            {/* Facebook & LinkedIn Icon */}
                            <div className='social-links pt-1 pt-lg-0'>
                                <a href='https://www.facebook.com/BrandonCAInc/' className='facebook'>
                                    <i className='bx bxl-facebook'></i>
                                </a>
                                <a href='https://www.linkedin.com/company/brandon-consulting-associates-inc' className='linkedin'>
                                    <i className='bx bxl-linkedin'></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}