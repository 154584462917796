import React, {useState} from "react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";



SwiperCore.use([EffectCoverflow, Pagination]);

export const SwiperComponent = () => {
  const [count, setCount] = useState(1);
  return (
    <div>
      <Swiper
        loop={true}
        speed={600}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        slidesPerView={'auto'}
        pagination={{
          type: 'bullets',
          clickable: true
        }}
        onSlideChange={(swiper) => {
          if (count === 5) {
            setCount(1);
            swiper.autoplay.stop();
            setTimeout(() => {
              swiper.autoplay.start();
            }, 3000);
          } else {
            setCount((prev) => prev + 1);
          }
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="testimonial-item">
            <h3>IT Services Company</h3>
            <p> <i className="bx bxs-quote-alt-left quote-icon-left"></i> I get frequent calls from my account manager at Brandon Consulting to see if their people are performing well and meeting our needs. They are proactive and help us think ahead for projects that are on the horizon. <i className="bx bxs-quote-alt-right quote-icon-right"></i> </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonial-item">
            <h3>BCA Placed Employee</h3>
            <p> <i className="bx bxs-quote-alt-left quote-icon-left"></i> I enjoy being part of the BCA team. They know me and understands my skill set and future goals. They found me a position that challenges me and keeps my interest and they offer me support throughout the process <i className="bx bxs-quote-alt-right quote-icon-right"></i> </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonial-item">

            <h3>Jim Douglas</h3>
            <h4>HR client</h4>
            <p> <i className="bx bxs-quote-alt-left quote-icon-left"></i> The customized partner program of temporary staffing has helped increase our business substantially. We are extremely happy. BCA gave us exactly what we wanted!” <i className="bx bxs-quote-alt-right quote-icon-right"></i> </p>
          </div>
        </SwiperSlide>

      </Swiper>
    </div>
  );
};

