import { React, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../images/logo.png'

export const Navbar = () => {

  const [mobileNav, setMobileNav] = useState(false);
  const [navClass, setNavClass] = useState('navbar');
  const [menuClass, setMenuClass] = useState('bi bi-list mobile-nav-toggle');
  // const [activeClass, setActiveClass] = React.useState('home');
  //bi mobile-nav-toggle bi-x


  function toggleMobileNav() {
    if (mobileNav) {
      setNavClass('navbar');
      setMobileNav(false);
      setMenuClass('bi bi-list mobile-nav-toggle');
    } else {
      setNavClass('navbar navbar-mobile');
      setMobileNav(true);
      setMenuClass('bi mobile-nav-toggle bi-x');
    }
  }

  

  return (
    <div>

      <div id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:info@brandonconsulting.com">info@brandonconsulting.com</a></i>
            <i className="bi bi-phone d-flex align-items-center ms-4"><a href="tel:(732) 650-9121">(732) 650-9121</a></i>

          </div>

          <div className="cta d-none d-md-flex align-items-center">
            <Link className="scrollto" to="/careers">Get Started</Link>
            {/* <a href="/careers" className="scrollto"></a> */}
          </div>
        </div>
      </div>

      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">


          <div className="logo">

            {/* <a href="/"><img src={process.env.PUBLIC_URL + "assests/img/logo.png"} alt="" className="img-fluid" /></a> */}
            <Link to="/"><img src={logo} alt="" className="img-fluid" /></Link>


          </div>


          <nav id="navbar" className={navClass}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/capabilities">Capability</Link></li>
              <li><Link to="/careers">Careers</Link></li>
              {/* <li><a className={window.location.pathname === '#contact' ? 'active': ''} href="index.html#contact">Contact</a></li> */}
            </ul>
            <i onClick={toggleMobileNav} className={menuClass}></i>
          </nav>

        </div>

      </header>

    </div>
  )
}