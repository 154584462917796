import React from 'react';
import {  Routes, Route } from "react-router-dom";
import { MainPage } from './pages/MainPage.js';
import { Careers } from './pages/Careers.js';
import { Capabilities } from './pages/Capabilities.js';
import { Services } from './pages/Services.js';
import { About } from './pages/About.js';

const App = () => {
  return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/capabilities" element={<Capabilities />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
      </Routes>
  );
}

export default App;
