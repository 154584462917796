import { React, useState,useEffect } from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Link } from "react-router-dom";


export const Services = () => {

	const [isActive1, setIsActive1] = useState(true);
	const [isActive2, setIsActive2] = useState(true);
	const [isActive3, setIsActive3] = useState(true);

	const handleClick1 = event => {
		// 👇️ toggle isActive state on click
		setIsActive2(true)
		setIsActive3(true)
		setIsActive1(current => !current);
	};

	const handleClick2 = event => {
		// 👇️ toggle isActive state on click
		setIsActive1(true)
		setIsActive3(true)
		setIsActive2(current => !current);
	};

	const handleClick3 = event => {
		// 👇️ toggle isActive state on click
		setIsActive1(true)
		setIsActive2(true)
		setIsActive3(current => !current);
	};

	useEffect(() =>{
		window.scrollTo(0, 100)
		let cardchecker = window.location.href
		if(cardchecker.includes('#card1')){
			setIsActive1(false)
		}
		if(cardchecker.includes('#card2')){
			setIsActive2(false)
		}
		if(cardchecker.includes('#card3')){
			setIsActive3(false)
		}
		  
	},[]);
	// const [isOpenC1, setIsOpenC1] = useState(false);
	// const [isOpenC2, setIsOpenC2] = useState(false);
	// const [isOpenC3, setIsOpenC3] = useState(false);


	// const card1 = () => {

	// 	setIsOpenC2(false)
	// 	setIsOpenC3(false)
	// 	setIsOpenC1(!isOpenC1)

	// }

	// const card2 = () => {
	// 	setIsOpenC1(false)
	// 	setIsOpenC3(false)
	// 	setIsOpenC2(!isOpenC2)



	// }

	// const card3 = () => {
	// 	setIsOpenC1(false)
	// 	setIsOpenC2(false)
	// 	setIsOpenC3(!isOpenC3)


	// }




	return (
		<>
			<Navbar />
			<main id="main">

				<section id="breadcrumbs" className="breadcrumbs" style={{ paddingRight: '56px' }}>
					<div className="container">
						<ol>
							<li><Link to="/">Home</Link></li>
							<li>Services</li>
						</ol>
						<h2>Services</h2> </div>
				</section>

				<section className="about section-bg" data-aos="fade-up">
					<div className="container">

						<ul style={{ paddingLeft: '47px' }}>
							<li style={{ fontSize: 'larger' }}> Brandon Consulting targets the services we offer though years of experience in the industry and keeping up with future trends and business needs. We recruit personable, experienced, driven professionals able to deliver what you need, when you need it. We also offer project consulting services for design, development and integration of existing or customized applications and databases. </li>
						</ul>
						<div className="header">
							<h1 className="header__title">Featured Services</h1>
							<h2 className="header__subtitle" style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif' }}>Click on them to know more!</h2> </div>
						{/* <div class="row-r"> */}
						{/* <div class="column-r">
								<div class="card-r" onClick={() => card1()}>
									<span>IT Staffing and Consulting</span>

								</div>
								<br />
								{isOpenC1 && <><div className="card-content">
									<ul>
									<i className="bi bi-x" style={{ marginLeft: "95%"}} onClick={() => setIsOpenC1(false)} ></i> 
										<li> Our IT Staffing team specializes in providing on-site, high caliber, motivated IT professionals for temporary consulting, temp-to-hire or permanent employment for individual as well as full team placements. </li>
										<li> We know a candidate is much more than just a resume. Our recruiters get to know their personal skills as well as their technical ability. By knowing our staff and understanding your specific needs, we can match our candidates to fit both your project requirements and fit into your team. </li>
									</ul>
								</div></>}
							</div> */}

						{/* <div class="column-r">
								<div class="card-r" onClick={() => card2()}>
									<span>Project Consulting</span>
								</div>
								<br />
								{isOpenC2 && <div className="card-content">
									<ul>
									<i className="bi bi-x" style={{ marginLeft: "95%"}} onClick={() => setIsOpenC2(false)} ></i> 
										<li> Our Projects Group assists clients with the design, development and integration of existing or customized, applications and databases. From scope definition and requirement analysis to development to implementation and maintenance, our team offers the flexibility to creatively achieve our client's goals and confront their challenges. </li>
										<li> Our organizational framework is augmented by multiple on-shore and offshore business partners, ensuring that we address our clients' project needs with a full array of capabilities. This group specializes in providing offshore, fixed-price and custom-built software solutions in areas including finance, manufacturing, sales and distribution, retail and corporate finance, advertising and media, pharmaceuticals, project engineering and E-business. </li>
									</ul>
								</div>}
							</div> */}

						{/* <div class="column-r">
								<div class="card-r" onClick={() => card3()}>
									<span>Vendor Management</span>
								</div>
								<br />
								{isOpenC3 && <div className="card-content">
									<ul>
									<i className="bi bi-x" style={{ marginLeft: "95%"}} onClick={() => setIsOpenC3(false)} ></i> 
										<li> Our Vendor Management program's mission is to help clients manage the requisition of IT contract and full-time staff by effectively managing their existing vendors. </li>
										<li> We understand that, like most customers, you may have existing, multiple-vendors who are currently providing services. Our intention is not to replace them but rather to consolidate, manage and drive up the performance of all your existing vendors while driving down your costs and freeing up your valuable organizational resources. </li>
									</ul>
								</div>}
							</div> */}
						{/* </div> */}
						<div className="cards">
							<div className={isActive1 ? 'card1 [ is-collapsed ]' : 'card1 [ is-expanded ]' } onClick={handleClick1} id="card1" >
								<div className="card__inner [ js-expander ]"> <span>IT Staffing and Consulting</span> </div>
								<div className="card__expander"> <i className="bi bi-x [ js-collapser ]"></i>
									<ul>
										<li> Our IT Staffing team specializes in providing on-site, high caliber, motivated IT professionals for temporary consulting, temp-to-hire or permanent employment for individual as well as full team placements. </li>
										<li> We know a candidate is much more than just a resume. Our recruiters get to know their personal skills as well as their technical ability. By knowing our staff and understanding your specific needs, we can match our candidates to fit both your project requirements and fit into your team. </li>
									</ul>
								</div>
							</div>
							<div className={isActive2 ? 'card1 [ is-collapsed ]' : 'card1 [ is-expanded ]' } onClick={handleClick2} id="card2">
								<div className="card__inner [ js-expander ]"> <span>Project Consulting</span> </div>
								<div className="card__expander"> <i className="bi bi-x [ js-collapser ]"></i>
									<ul>
										<li> Our Projects Group assists clients with the design, development and integration of existing or customized, applications and databases. From scope definition and requirement analysis to development to implementation and maintenance, our team offers the flexibility to creatively achieve our client's goals and confront their challenges. </li>
										<li> Our organizational framework is augmented by multiple on-shore and offshore business partners, ensuring that we address our clients' project needs with a full array of capabilities. This group specializes in providing offshore, fixed-price and custom-built software solutions in areas including finance, manufacturing, sales and distribution, retail and corporate finance, advertising and media, pharmaceuticals, project engineering and E-business. </li>
									</ul>
								</div>
							</div>
							<div className={isActive3 ? 'card1 [ is-collapsed ]' : 'card1 [ is-expanded ]' } onClick={handleClick3} id="card3">
								<div className="card__inner [ js-expander ]"> <span>Vendor Management</span> </div>
								<div className="card__expander"> <i className="bi bi-x [ js-collapser ]"></i>
									<ul>
										<li> Our Vendor Management program's mission is to help clients manage the requisition of IT contract and full-time staff by effectively managing their existing vendors. </li>
										<li> We understand that, like most customers, you may have existing, multiple-vendors who are currently providing services. Our intention is not to replace them but rather to consolidate, manage and drive up the performance of all your existing vendors while driving down your costs and freeing up your valuable organizational resources. </li>
									</ul>
								</div>
							</div>
						</div>
						<div style={{ paddingTop: '40px' }}>
							<ul style={{ fontSize: 'larger', paddingLeft: '46px' }}>
								<li>We have aggressively initiated marketing and sales activities to offer IT services to government, financial and various other institutes by providing technology-driven business solutions to clients.</li>
							</ul>
							<ul style={{ fontSize: 'larger', paddingLeft: '46px' }}>
								<li><u>Full List Of Services:</u></li>
							</ul>
							<ol type="A" style={{ fontSize: 'larger', paddingLeft: '46px' }}>
								<li>Staff Augmentation</li>
								<li>Project Consulting Services</li>
								<li>Government Services</li>
								<li>Vendor Management</li>
								<li>Offshore Development</li>
								<li>Optimization Services</li>
								<li>Quality Management</li>
								<li>Software Maintenance</li>
							</ol>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	)
}