import React,{useState} from "react";
import { Link } from "react-router-dom";

import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";

export const Capabilities = () => {
    const [isActive1, setIsActive1] = useState(true);
	const [isActive2, setIsActive2] = useState(true);
	const [isActive3, setIsActive3] = useState(true);

	const handleClick1 = event => {
		// 👇️ toggle isActive state on click
		setIsActive2(true)
		setIsActive3(true)
		setIsActive1(current => !current);
	};

	const handleClick2 = event => {
		// 👇️ toggle isActive state on click
		setIsActive1(true)
		setIsActive3(true)
		setIsActive2(current => !current);
	};

	const handleClick3 = event => {
		// 👇️ toggle isActive state on click
		setIsActive1(true)
		setIsActive2(true)
		setIsActive3(current => !current);
	};
    return (
        <>
            <Navbar />
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs" style={{ paddingRight: '56px' }}>
                    <div className="container">
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>Capibility</li>
                        </ol>
                        <h2>Capibility</h2>
                    </div>
                </section>

                <section className="about section-bg" data-aos="fade-up">
                    <div className="container">
                        <ul style={{ paddingLeft: '47px' }}>
                            <li style={{ fontSize: 'larger' }}>Brandon Consulting Associates Inc. is a full service staffing and IT consulting firm, providing IT and non-IT solutions for a nationwide client base across multiple industry sectors. We assist our clients with their temporary and permanent hiring, IT consulting and project based consulting needs. </li>
                        </ul>
                        {/* <div className="row-r">
                            <div className="column-r">
                                <div className="card-r" onClick={() => card1()}>
                                    <span>Mission</span>
                                    
                                </div>
                                <br />
                                {isOpenC1 && <><div className="card-content">  
                                 
                                                
                                    <ul>
                                    <i className="bi bi-x" style={{ marginLeft: "95%"}} onClick={() => setIsOpenC1(false)} ></i>  
                                        <li> Our Projects Group assists clients with the design, development and integration of existing or customized, applications and databases. From scope definition and requirement analysis to development to implementation and maintenance, our team offers the flexibility to creatively achieve our client's goals and confront their challenges. </li>
                                        <li> Our organizational framework is augmented by multiple on-shore and offshore business partners, ensuring that we address our clients' project needs with a full array of capabilities. This group specializes in providing offshore, fixed-price and custom-built software solutions in areas including finance, manufacturing, sales and distribution, retail and corporate finance, advertising and media, pharmaceuticals, project engineering and E-business. </li>
                                    </ul>
                                </div></>}
                            </div>

                            <div className="column-r">
                                <div className="card-r" onClick={() => card2()}>
                                    <span>Core Values</span>
                                </div>
                                <br />
                                {isOpenC2 && <div className="card-content">
                                    <ul>
                                    <i className="bi bi-x" style={{ marginLeft: "95%"}} onClick={() => setIsOpenC2(false)} ></i> 
                                        <li> Excellence is our passion. We strive to be the best in our field. We value collaboration with our clients/partners </li>
                                    </ul>
                                </div>}
                            </div>

                            <div className="column-r">
                                <div className="card-r" onClick={() => card3()}>
                                    <span>Pride</span>
                                </div>
                                <br />
                                {isOpenC3 && <div className="card-content">
                                    <ul>
                                    <i className="bi bi-x" style={{ marginLeft: "95%"}} onClick={() => setIsOpenC3(false)} ></i> 
                                        <li> Reputation of exceeding our client expectations by delivering IT solutions and resources that are knowledgeable, qualified, reliable, experienced and valued. </li>
                                    </ul>
                                </div>}
                            </div>
                        </div> */}

                        <div className="cards">
                            <div className={isActive1 ? 'card1 [ is-collapsed ]' : 'card1 [ is-expanded ]' } onClick={handleClick1} id="card1">
                                <div className="card__inner [ js-expander ]"> <span>Mission</span> </div>
                                <div className="card__expander">
                                    <i className="bi bi-x [ js-collapser ]"></i>
                                    <ul>
                                        <li> To be a Vendor of Choice and Employer of Choice thereby helping our clients leverage our value-added consulting services to manage projects and programs</li>
                                        {/* <li> Our organizational framework is augmented by multiple on-shore and offshore business partners, ensuring that we address our clients' project needs with a full array of capabilities. This group specializes in providing offshore, fixed-price and custom-built software solutions in areas including finance, manufacturing, sales and distribution, retail and corporate finance, advertising and media, pharmaceuticals, project engineering and E-business. </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className={isActive2 ? 'card1 [ is-collapsed ]' : 'card1 [ is-expanded ]' } onClick={handleClick2} id="card2">
                                <div className="card__inner [ js-expander ]"> <span>Core Values</span> </div>
                                <div className="card__expander">
                                    <i className="bi bi-x [ js-collapser ]"></i>
                                    <ul>
                                        <li> Excellence is our passion. We strive to be the best in our field. We value collaboration with our clients/partners </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={isActive3 ? 'card1 [ is-collapsed ]' : 'card1 [ is-expanded ]' } onClick={handleClick3} id="card3">
                                <div className="card__inner [ js-expander ]"> <span>Pride</span> </div>
                                <div className="card__expander">
                                    <i className="bi bi-x [ js-collapser ]"></i>
                                    <ul>
                                        <li> Reputation of exceeding our client expectations by delivering IT solutions and resources that are knowledgeable, qualified, reliable, experienced and valued. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="column">
                                <h4>Core Competencies</h4>
                                <ol type="A" style={{ fontSize: 'medium', paddingLeft: '46px' }}>
                                    <li>IT Consulting</li>
                                    <li>Permanent Staffing</li>
                                    <li>Contingent Staffing</li>
                                    <li>Turnkey Project Consulting</li>
                                    <li>Vendor Management Services</li>
                                    <li>Healthcare Staffing</li>
                                </ol>
                            </div>
                            <div className="column">
                                <h4>Key Differentiators</h4>
                                <ol type="A" style={{ fontSize: 'medium', paddingLeft: '46px' }}>
                                    <li>Agile Recruitment Methodology</li>
                                    <li>Tailored Staffing&Recruitment</li>
                                    <li>SLA Driven Recruitment</li>
                                    <li>Target Selection Methodology</li>
                                    <li>Experienced Recruitment Team</li>
                                    <li>Technology & Managed Services</li>
                                    <li>Project Management</li>
                                    <li>Competitive & Reasonable Pricing</li>

                                </ol>
                            </div>
                            <div className="column">
                                <h4>Industry Experience</h4>
                                <ol type="A" style={{ fontSize: 'medium', paddingLeft: '46px' }}>
                                    <li>Banking and Brokerage</li>
                                    <li>Telecommunication</li>
                                    <li>Insurance</li>
                                    <li>Healthcare</li>
                                    <li>Pharmaceuticals</li>
                                    <li>Public Sector</li>
                                    <li>Energy</li>
                                    <li>Travel</li>
                                    <li>Information Technology</li>
                                </ol>
                            </div>
                            <div className="column">
                                <h4>MBE Certified</h4>
                                <ol type="A" style={{ fontSize: 'medium', paddingLeft: '46px' }}>
                                    <li>New York State</li>
                                    <li>New York City</li>
                                    <li>Port Authority of NY and NJ</li>
                                    <li>State of New Jersey</li>
                                    <li>Commonwealth of Virginia</li>
                                    <li>Fulton County,Georgia</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row">
                            <div className="column">
                                <h4>NAICS CODES</h4>
                                <ol type="A" style={{ fontSize: 'medium', paddingLeft: '46px' }}>
                                    <li>541511: Custom Programming</li>
                                    <li>541512: Systems Design</li>
                                    <li>541513: Computer Facilities Mgmt</li>
                                    <li>541519: Other Computer Services</li>
                                    <li>561320 Temporary Staffing Services</li>
                                </ol>
                            </div>
                            <div className="column">
                                <h4>NIGP CODES</h4>
                                <ol type="A" style={{ fontSize: 'medium', paddingLeft: '46px' }}>
                                    <li>918-71-00: IT Consulting</li>
                                    <li>208-37-00; Database Software</li>
                                    <li>920-14-00: Applications Software</li>
                                    <li>920-40-00: Computer Programming Services</li>

                                </ol>
                            </div>

                        </div>


                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}